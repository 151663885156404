<template>
  <div class="flex items-center justify-center relative w-full h-screen">
    <img
      src="@/assets/img/bg.jpg"
      class="absolute left-0 top-0 w-full h-full object-cover"
      alt=""
    />
    <img
      src="@/assets/img/bg-gradient.svg"
      class="absolute left-1/2 transform -translate-x-1/2 opacity-25 top-0 mx-auto h-full object-cover"
      alt=""
    />
    <div class="text-center py-20 relative">
      <img
        src="@/assets/img/logo_white_full.svg"
        class="w-32 mx-auto mb-10"
        alt=""
      />
      <h4>
        Elevated digital finance.
        <a href="#" target="_blank">Powered by Terra</a>
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Digital Finance',
  },
};
</script>
